







































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useAbstract from "@/use/abstract";
import { v4 as uuid } from "uuid";
import validator from "validator";

export default defineComponent({
  components: {
    MAbstractParticipant: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-participant.vue"
      ),
    MAbstractLanguages: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-languages.vue"
      ),
    MAbstractType: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-type.vue"
      ),
    MAbstractAuthors: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-authors.vue"
      ),
    MAbstractConsents: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-consents.vue"
      ),
    MAbstractTopics: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-topics.vue"
      ),
    MAbstractSymposium: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-symposium.vue"
      ),
  },
  props: {
    abstract: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      abstract: {},
      topics: [],
      config: {} as any,
      isValid: false,
      file: null as any,
      publications: [],
      maxLength: undefined as any,
      longSummaryMaxLength: undefined as any,
      wordLimit: 0,
      longSummaryWordLimit: 0,
    });

    const model = reactive({
      participantId: "",
      type: "presentation",
      authors: [
        {
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          affiliation: "",
        },
      ],
      sessionTopic: "",
      title: "",
      keywords: "",
      short: "",
      long: "",
      symposium: "",
      file: "",
      languageVersion: "polish",
      consentsIds: [],
      publication: "",
    });

    const getConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/abstract-config`)
        .then(({ data }) => {
          state.config = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(getConfig);

    // Start fetch abstract

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/abstract/${props.abstract}/system`)
        .then(({ data: { abstract } }) => {
          state.abstract = abstract;
          model.participantId = abstract.participant?.id;
          model.type = abstract.type;
          model.authors = abstract.authors;
          model.sessionTopic = abstract.sessionTopic?.id;
          model.title = abstract.title;
          model.keywords = abstract.keywords
            .map((el: any) => el["value"])
            .toString();
          model.short = abstract.short || null;
          model.long = abstract.long || null;
          model.symposium = abstract.symposium?.id;
          model.file = abstract.files[0]?.id;
          model.languageVersion = abstract.languageVersion;
          model.consentsIds = abstract.consents?.map((el: any) => el.id);
          model.publication = abstract.publication?.id;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // End fetch abstract

    const fetchTopics = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/session-topic`)
        .then(({ data: { sessionTopic } }) => {
          state.topics = sessionTopic;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.topics = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTopics);

    const fetchPublications = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/publication`)
        .then(({ data: { publications } }) => {
          state.publications = publications;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchPublications);

    // Start get abstract file

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    // End get abstract file

    const addAuthor = () => {
      model.authors.push({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        affiliation: "",
      });
    };

    const removeAuthor = (index: number) => {
      model.authors.splice(index, 1);
    };

    const consents = computed(() => {
      if (state.config) {
        if (state.config.consents && state.config.consents.length) {
          return state.config.consents;
        }
      } else return [];
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      let file = "";
      const formData = new FormData();
      formData.append("file", state.file);

      if (state.file) {
        await axiosInstance
          .post(`file/abstract/panel/${root.$route.params.id}`, formData, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(({ data: { id } }) => {
            file = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        participantId: model.participantId,
        languageVersion: model.languageVersion,
        type: model.type,
        title: model.title,
        keywords: model.keywords
          .split(",")
          .map((el: string) => ({ value: el.replace(/^\s+|\s+$/g, "") })),
        short: model.short || undefined,
        authors: model.authors,
        symposium: model.symposium || undefined,
        sessionTopic: model.sessionTopic,
        fileId: file || undefined,
        long: model.long || null,
        consentsIds: model.consentsIds || [],
        publicationId: model.publication || undefined,
      };

      axiosInstance
        .put(`/abstract/${props.abstract}/system`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.abstract.abstractEdit.success"),
          });
          setTimeout(() => {
            emit("close");
            emit("fetch");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    const wordCount = (str: string, type: string) => {
      if (type === "short") {
        state.wordLimit = str.split(" ").length;
        if (
          state.config &&
          state.config.limitType === "word" &&
          state.config.maxLimit <= state.wordLimit - 1
        ) {
          state.maxLength = model.short.length;
        } else {
          state.maxLength = undefined;
        }
      } else {
        state.longSummaryWordLimit = str.split(" ").length;
        if (
          state.config &&
          state.config.longSummaryType === "word" &&
          state.config.longSummaryLimit <= state.longSummaryWordLimit - 1
        ) {
          state.longSummaryMaxLength = model.short.length;
        } else {
          state.longSummaryMaxLength = undefined;
        }
      }
    };

    watch(
      () => model.short,
      () => {
        if (state.config && state.config.limitType === "word") {
          wordCount(model.short, "short");
        }
      }
    );

    watch(
      () => model.long,
      () => {
        if (state.config && state.config.longSummaryType === "word") {
          wordCount(model.long, "long");
        }
      }
    );

    const rules = {
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return {
      state,
      model,

      getTypeName,
      getStatusName,

      getFile,

      addAuthor,
      removeAuthor,
      onSubmit,

      rules,
      consents,
    };
  },
});
